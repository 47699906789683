<template>
    <div>
        <FunctionDefinition></FunctionDefinition>
    </div>
</template>

<script>
import FunctionDefinition from '@/components/functionDefinition'
export default{
    components: {FunctionDefinition},
}
</script>




